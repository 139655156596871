import Image from "next/image";
import BrownButton from "../components/buttons/BrownButton";
import Logo from "../components/images/The_Boho_Market_Logo.png";
import mainLogo from "../public/images/mainLogo.svg";

const NotFound = () => {
  return (
    <div className="container">
      <div className="wrapper-notFound">
        <div className="center__logo">
          <Image
            src={mainLogo}
            width="220"
            height="70"
            alt=""
          />
        </div>
        <div className="unsbuscribe__backgroundImage">
          <Image
            src="https://res.cloudinary.com/dkceblkn7/image/upload/v1644945463/boho%20app%20assets/background%20images/Group_456_ouqlxv.svg"
            width="860"
            height="356"
            alt=""
          />
        </div>

        <div className="notFound__link">
          <BrownButton content="Go to Home" link="/dashboard" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
